import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../themeV2";
import quotationMarks from "../images/quotation-marks.png";
import angleRight from "../images/angle-right.png";
import angleLeft from "../images/angle-left.png";

function CustomPrevArrow({ onClick }) {
  return <Box
    onClick={onClick}
    sx={{
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
      cursor: "pointer",
    }}
  >
    <img src={angleLeft} alt="angle left" style={{ width: "25px" }} />
  </Box>;
}

function CustomNextArrow({ onClick }) {
  return <Box
    onClick={onClick}
    sx={{
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
      cursor: "pointer",
    }}
  >
    <img src={angleRight} alt="angle right" style={{ width: "25px" }} />
  </Box>;
}

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  cssEase: "linear",
  pauseOnHover: false,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

function CommentsSlider({sliders}) {
  const sliderRef = useRef(null);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Slider ref={sliderRef} {...settings}>
          {sliders.map((slider, index) => (
            <Box key={index} display="flex  !important" justifyContent="center" padding={2}>
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  top: "0",
                  zIndex: 1,
                }}
              >
                <img
                  src={quotationMarks}
                  alt="quotation marks"
                  style={{ width: "40px" }}
                />
              </Box>
              <Box
                py={2}
                px={{ xs: 2, sm: 3 }}
                width={{ xs: "80%", sm: "90%" }}
                sx={{
                  backgroundColor: theme.palette.colors.black.light,
                  borderRadius: "14px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Typography variant="heading" fontWeight={400}>
                  {slider.comment}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
}

export default CommentsSlider;
